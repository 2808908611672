<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-con"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Concienciación"
                  src="/media/cide/ecosistemas/CON.png"
                />
              </v-avatar>

              <h1 class="ml-3 texto-ecosistema-elc">
                Concienciación. {{ currentObject.nombre }}
              </h1>

              <v-spacer />
              <v-select
                v-model="selected"
                :items="this.$regions"
                item-text="nombre"
                item-value="slug"
                @change="changeComunidad"
              />

              <v-spacer />

              <router-link
                to="/concienciacion"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Volver a los datos nacionales
              </router-link>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Media Nacional</div>
                <div class="col-xl-2">
                  {{ currentObject.nombre }}
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2021
                  </span>
                </div>
                <div v-if="dataJson[0]" class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].media }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].comunidad }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].media }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].comunidad }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Grado de preocupación por la contaminación y la emisión de
                    GEI 2023
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].media }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].comunidad }}
                  </span>
                  10: Altamente preocupado - 0: Sin preocupación
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Interés en la participación en medidas de reducción de la huella
              de carbono en los hogares rurales
            </v-card-title>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson2[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson2[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson2[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Grado de conocimiento eléctrico en los hogares rurales
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson3[0]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson3[1]"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="400"
                    data-format="json"
                    :data-source="dataJson3[2]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div style="width: 800px">
            <canvas id="acquisitions" />
          </div>
        </div>
        <!--<div class="col-xl-4">
    <v-card class="mx-auto rounded-lg" elevation="10" outlined>
      <v-card-title>Grado de conocimiento de los consumidores domésticos de su factura eléctrica y asesoramiento percibido. </v-card-title>

      <v-card-text>
        <fusioncharts
          type="pie3d"
          width="100%"
          height="400"
          dataFormat="jsonurl"
          :dataSource="'/data/CON/comunidad/' + this.currentSlug + '_inp07.json'"
        >
        </fusioncharts>
      </v-card-text>
    </v-card>
  </div>-->
      </div>

      <div class="row" />

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.comunidad,
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },
      dataJson: [],
      dataJson2: [],
      dataJson3: [],
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.comunidad;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug == this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    changeComunidad(selected) {
      this.$router.push(
        {
          path: `/concienciacion/${selected}`,
        },
        () => {
          this.getJsonData();
        }
      );
    },
    getJsonData() {
      fetch("/data/CON/comunidad/" + this.currentSlug + "_data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });

      fetch("/data/CON/comunidad/" + this.currentSlug + "_inp02.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson2 = data;
        });

      fetch("/data/CON/comunidad/" + this.currentSlug + "_inp05.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson3 = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
